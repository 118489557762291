$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    /*.before(
      '<button class="hamburger hamburger--squeeze is-flex is-justify-content-space-between is-align-items-center px-0" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box is-hidden-tablet"><span class="hamburger-inner"></span></span><span class="has-text-weight-bold has-text-white is-size-10">Menú</span><svg class="is-hidden-mobile" xmlns="http://www.w3.org/2000/svg" width="18.835" height="18.835"><g><g fill="#fff"><path d="M7.595 0h3.645v18.835H7.595z"/><path d="M18.835 7.595v3.645H0V7.595z"/></g></g></svg></button>'
    )*/
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("body").toggleClass("js-menu-open");
  });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  var menuItems = document.querySelectorAll("#menu li.has-submenu");
  Array.prototype.forEach.call(menuItems, function (el, i) {
    var activatingA = el.querySelector("a");
    var btn =
      '<button><span><span class="is-sr-only">Mostra el submenú per “' +
      activatingA.text +
      "”</span></span></button>";
    activatingA.insertAdjacentHTML("afterend", btn);
    // activatingA.setAttribute("aria-expanded", "false");

    el.querySelector("button").addEventListener("click", function (event) {
      var currentMenu = this.parentNode;
      Array.prototype.forEach.call(menuItems, function (el, i) {
        if (currentMenu !== el) {
          el.classList.remove("open");
          el.classList.add("close");
          //el.querySelector("a").setAttribute("aria-expanded", "false");
          el.querySelector("button").setAttribute("aria-expanded", "false");
        }
      });
      //console.log(this.parentNode.className);
      if (this.parentNode.classList.contains("close")) {
        this.parentNode.classList.add("open");
        this.parentNode.classList.remove("close");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "true");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "true");
      } else {
        this.parentNode.classList.add("close");
        this.parentNode.classList.remove("open");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "false");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "false");
      }
      event.preventDefault();
    });
  });

  $('#open-modal').magnificPopup({
    items: {
      src: '#modal-content',
      type: 'inline'
    },
    closeBtnInside: true
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // -------
  // Sliders
  // -------
  // TODO: idiomes
  // Slider home mobile
  $('.slider--home-mobile').slick({
    dots: true,
    appendArrows: $('.slider-nav--mobile'),
    appendDots: $('.slider-nav--mobile .slider-nav__dots'),
    customPaging( slider, i ) {
      return $( '<button type="button"><span class="slick-sr-only">Ves a la diapositiva </span><span>' + ( i+1 ) + '</span></button>' );
    },
  });

  // Slider home desktop
  $('.slider--home-desktop').slick({
    dots: true,
    appendArrows: $('.slider-nav--desktop'),
    appendDots: $('.slider-nav--desktop .slider-nav__dots'),
    customPaging( slider, i ) {
      return $( '<button type="button"><span class="slick-sr-only">Ves a la diapositiva </span><span>' + ( i+1 ) + '</span></button>' );
    },
  });

  // Slider news
  $('.slider-news').slick({
    arrows: false,
    dots: false,
    slidesToShow: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 2.2,
          infinite: true,
        }
      },
      {
        breakpoint: 550,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1.2,
          initialSlide: 0,
          infinite: false,
        }
      }
    ]
  });

  // Slider home "Què és"
  $('.slider--que-es').slick({
    slidesToShow: 2.3,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-que-es'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          arrows: false,
          slidesToShow: 1.6,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 625,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1.3,
          initialSlide: 0,
          arrows: false,
        }
      }
    ]
  });

  // Slider Donacions "Coneix les històries de persones que han rebut vida"
  $('.slider--histories').slick({
    slidesToShow: 3.3,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-histories'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 2.3,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          arrows: false,
          slidesToShow: 1.3,
          initialSlide: 0,
          arrows: false,
        }
      }
    ]
  });

  // Slider Productes i serveis - Sang "Últims recursos descarregables"
  $('.slider--recursos').slick({
    slidesToShow: 2.3,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-recursos'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1.6,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        }
      },
      {
        breakpoint: 625,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1.3,
          initialSlide: 0,
        }
      }
    ]
  });

  // Slider Recerca "Projectes d'investigació"
  $('.slider--projectes').slick({
    slidesToShow: 4.5,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-projectes'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.2,
          centerMode: false,
          centerPadding: '30px',
          // arrows: false,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2.2,
          centerMode: false,
          centerPadding: '20px',
          initialSlide: 0,
          // arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
          centerPadding: '20px',
          initialSlide: 0,
          // arrows: false,
        }
      }
    ]
  });

  $('.slider--apd').slick({
    slidesToShow: 2.5,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-projectes'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2.2,
          centerMode: false,
          centerPadding: '30px',
          // arrows: false,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1.5,
          centerMode: false,
          centerPadding: '20px',
          initialSlide: 0,
          // arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
          centerPadding: '20px',
          initialSlide: 0,
          // arrows: false,
        }
      }
    ]
  });

  // Slider Recerca "Últimes memòries"
  $('.slider--memories').slick({
    slidesToShow: 2.3,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-memories'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1.6,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        }
      },
      {
        breakpoint: 625,
        settings: {
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1.3,
          initialSlide: 0,
        }
      }
    ]
  });

  // Slider Documents "pisos"
  $('.slider--documents').each(function(index) {
    $(this).slick({
      slidesToShow: 3.3,
      infinite: false,
      centerMode: false,
      centerPadding: '40px',
      appendArrows: $('.slider-nav-documents').eq(index),
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 2.3,
            initialSlide: 0,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          }
        },
        {
          breakpoint: 625,
          settings: {
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 1.3,
            initialSlide: 0,
          }
        }
      ]
    });
  });

  // Slider Escoles "Coneix a altres escoles com la teva que ja formen part de la nostra comunitat"
  $('.slider--escoles').slick({
    slidesToShow: 1,
    appendArrows: $('.slider-nav-escoles'),
  });

  // Slider Testimonis "Descobreix els testimonis de professionals com tu fent-ho possible"
  $('.slider--testimonis').slick({
    slidesToShow: 1,
    appendArrows: $('.slider-nav-testimonis'),
  });

  // Slider Ofertes de feina
  $('.slider--ofertes').slick({
    slidesToShow: 4.5,
    infinite: false,
    centerMode: false,
    centerPadding: '40px',
    appendArrows: $('.slider-nav-ofertes'),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.2,
          centerMode: false,
          centerPadding: '30px',
          // arrows: false,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2.2,
          centerMode: false,
          centerPadding: '20px',
          initialSlide: 0,
          // arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
          centerPadding: '20px',
          initialSlide: 0,
          // arrows: false,
        }
      }
    ]
  });

  // ---------------------------------
  // Gràfic cercles "Grups de recerca"
  // ---------------------------------
  $(".grafic-gr__text").on("click", function() {
    // Get the text number from the data attribute
    var textNumber = $(this).data("text-number");

    // Check if the corresponding details element is open
    var isOpen = $(".desplegable-destacat-b--" + textNumber).prop("open");

    // Remove styles from all elements if the details element is open
    if (isOpen) {
      $(".grafic-gr__mask, .grafic-gr__text, .grafic-gr__circles").css("opacity", "");
    } else {
      // Close all other details elements
      $(".desplegable-destacat-b").not(".desplegable-destacat-b--" + textNumber).prop("open", false);

      // Remove styles from all elements
      $(".grafic-gr__mask, .grafic-gr__text, .grafic-gr__circles").css("opacity", "");

      // Apply specific styles based on the clicked element
      $(".grafic-gr__mask--" + textNumber).css("opacity", 1);
      $(".grafic-gr__text").not(".grafic-gr__text--" + textNumber).css("opacity", 0.47);
      $(".grafic-gr__circles").css("opacity", 0.47);
    }

    // Toggle the 'open' attribute for the corresponding details element
    $(".desplegable-destacat-b--" + textNumber).prop("open", !isOpen);
  });

  // --------
  // Counters https://github.com/srexi/purecounterjs
  // --------
  new PureCounter({
    // selector: '.purecounter',
    // start: 0,
    // end: 100,
    // duration: 2,
    // delay: 10,
    // once: true,
    // repeat: false,
    // decimals: 0,
    legacy: false,
    // filesizing: false,
    // currency: false,
    // separator: false,
  });

  // ------------
  // Left wrapper (per alinear el .wrapper--left)
  // ------------
  function updateMargin() {
    var marginLeft = $('.wrapper').css('margin-left');
    $('.wrapper--left').css('margin-left', marginLeft);
  }
  updateMargin();
  $(window).on('resize', function () {
    updateMargin();
  });

  // -------------------------------
  // Espai superior al .main-content (perquè no quedi tapat pel .sub-nav)
  // -------------------------------
  function updatePadding() {
    var paddingTop = $('.has-sub-nav .main-header').innerHeight();
    $('.has-sub-nav .main-content').css('padding-top', paddingTop + 30);
  }
  updatePadding();
  $(window).on('resize', function () {
    updatePadding();
  });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
    // {
    // offset: 0,
    // tolerance: 5,
    // classes: {
    //   initial: "animated",
    //   pinned: "slideDown",
    //   unpinned: "slideUp"
    // }
  // }
  // );

  // -----------
  // File upload
  // -----------
  $('input[type="file"]').change(function () {
    var i = $(this).next().find(".file-label").clone();
    var file = $('input[type="file"]')[0].files[0].name;
    $(this).next().find(".file-label").text(file);
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
                ? index + 1
                : e.which === 40
                  ? "down"
                  : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
                ? switchTab(e.currentTarget, tabs[dir])
                : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }

  // ------
  // Videos
  // ------
  $('.boxes-grid--1__item').each(function() {
    var video = $(this).find('video')[0];

    $(this).on('mouseenter', function () {
      if (video) {
        video.play();
      }
    }).on('mouseleave', function () {
      if (video) {
        video.pause();
      }
    });
  });

});


// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
// (function () {
//   // Get all the headings
//   const headings = document.querySelectorAll('.collapsible__title')

//   Array.prototype.forEach.call(headings, heading => {
//     // Give each <h2> a toggle button child
//     // with the SVG plus/minus icon
//     heading.innerHTML = `
//       <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center p-2">
//         ${heading.textContent}
//         <svg class="icon is-flex-shrink-0" aria-hidden="true" focusable="false">
//             <use xlink:href="/media/img/svg-symbols.svg#angle-down"></use>
//         </svg>
//       </button>
//     `
//     // Function to create a node list
//     // of the content between this heading and the next
//     const getContent = (elem) => {
//       let elems = []
//       while (elem.nextElementSibling && elem.nextElementSibling.className !== 'collapsible__title') {
//         elems.push(elem.nextElementSibling)
//         elem = elem.nextElementSibling
//       }

//       // Delete the old versions of the content nodes
//       elems.forEach((node) => {
//         node.parentNode.removeChild(node)
//       })

//       return elems
//     }

//     // Assign the contents to be expanded/collapsed (array)
//     let contents = getContent(heading)

//     // Create a wrapper element for `contents` and hide it
//     let wrapper = document.createElement('div')
//     wrapper.hidden = true

//     // Add each element of `contents` to `wrapper`
//     contents.forEach(node => {
//       wrapper.appendChild(node)
//     })

//     // Add the wrapped content back into the DOM
//     // after the heading
//     heading.parentNode.insertBefore(wrapper, heading.nextElementSibling)

//     // Assign the button
//     let btn = heading.querySelector('button')

//     btn.onclick = () => {
//       // Cast the state as a boolean
//       let expanded = btn.getAttribute('aria-expanded') === 'true' || false

//       // Switch the state
//       btn.setAttribute('aria-expanded', !expanded)
//       // Switch the content's visibility
//       wrapper.hidden = expanded
//     }
//   })

//   if ($('.collapsible__title').length > 0 && window.location.hash.indexOf('#entry-') > -1 && $(window.location.hash).length > 0) {
//     $(window.location.hash).find('button').attr('aria-expanded', true);
//     $(window.location.hash).next().show();
//     $('html, body').animate({
//         scrollTop: $(window.location.hash).parent().offset().top
//     }, 1000);
//   }

// })();


/** donacions web antiga */
$(document).ready(function () {

	$("#boto-calendari").on("click", function(e) {
		e.preventDefault();
		if ($('.calendari-opcions').hasClass('selected')){
		  $('.calendari-opcions').removeClass('selected');
		  $('.calendari-opcions').attr('aria-hidden', 'true');
		}else{
		  $('.calendari-opcions').addClass('selected');
		  $('.calendari-opcions').attr('aria-hidden', 'false');
		}
	});
	$("body").on("click", function(e){
	  if(e.target.id == "boto-calendari")
		  return;
	  if ($('.calendari-opcions').hasClass('selected')){
		$('.calendari-opcions').removeClass('selected');
		$('.calendari-opcions').attr('aria-hidden', 'true');
	  }
	});

	if ($('body.inscdonacions form.hospital').length > 0) {
        $('body.inscdonacions form.hospital').find('select[name="hospital"]').not('.iphone').select2();
        function inscSubmit() {
            var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            var day = $('#hospital_day').val();
            var hora = $('#hospital_hour').val();

            if ((hospital == '' || hospital == undefined) ||
                (day == '' || day == undefined) ||
                (hora == '' || hora == undefined)) {
                $('body.inscdonacions form.hospital button').hide();
            } else {
                $('body.inscdonacions form.hospital button').show();
            }
        }
        $('body.inscdonacions form.hospital button').hide();
        /*$('body.inscdonacions form.hospital select[name="hospital"]').on('change', function(event){
            event.preventDefault();

            var extimageLoading = 'gif';
            //if (feature.svg) {
            //    extimageLoading = 'svg';
            //}
            $("body.inscdonacions form.hospital").prepend('<div class="loading"><img src="/media/img/loading-ajax.'+ extimageLoading +'" alt="Carregant..."></div>');

            var rowCalendar = $('body.inscdonacions form.hospital .calendarRow');
            var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            $.get("./", { hospital: hospital}, function(data) {
                rowCalendar.html(data);
                $("body.inscdonacions form.hospital .loading").remove();
                inscSubmit();
            });
            return false;
        });*/
        $('body.inscdonacions form.hospital').on('click', '.calendari thead a', function(event){
            event.preventDefault();

            var extimageLoading = 'gif';
            //if (feature.svg) {
            //    extimageLoading = 'svg';
            //}
            $("body.inscdonacions form.hospital").prepend('<div class="loading"><img src="/media/img/loading-ajax.'+ extimageLoading +'" alt="Carregant..."></div>');

            var calendar = $(this).closest('div');
            var month = $(this).data('month');
            var day = $('#hospital_day').val();
            //var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            var hospital = $('body.inscdonacions form.hospital').find('input[name="hospital"]').val();
            $.get( "./calendar.php", { hospital: hospital, month: month, day: day }, function(data) {
                calendar.html(data);
                $("body.inscdonacions form.hospital .loading").remove();
                inscSubmit();
            });
            return false;
        });
        $('body.inscdonacions form.hospital').on('click', '.calendari tbody a', function(event){
            event.preventDefault();

            var extimageLoading = 'gif';
            //if (feature.svg) {
            //    extimageLoading = 'svg';
            //}
            $("body.inscdonacions form.hospital").prepend('<div class="loading"><img src="/media/img/loading-ajax.'+ extimageLoading +'" alt="Carregant..."></div>');

            var rowCalendar = $('body.inscdonacions form.hospital .calendarRow');
            //var hospital = $('body.inscdonacions form.hospital').find('select[name="hospital"]').val();
            var hospital = $('body.inscdonacions form.hospital').find('input[name="hospital"]').val();
            var day = $(this).data('time');
            $.get( "./", { hospital: hospital, day: day }, function(data) {
                rowCalendar.html(data);
                $("body.inscdonacions form.hospital .loading").remove();
                inscSubmit();
            });
            return false;
        });
        $('body.inscdonacions form.hospital').on('change', '#hospital_hour', function(event){
            inscSubmit();
            return false;
        });
    }
    $( '#legallabel').click(function(event) {
            event.preventDefault();
            $('#legalhover').show();
        });
    if ($('body.inscdonacionshospitals form.reserva').length > 0) {




        if (
            $('body.inscdonacionshospitals form.reserva .dades-hospital').length == 0 &&
            $('body.inscdonacionshospitals form.reserva select[name="hospital"] option:selected').length > 0) {
            $("body.inscdonacionshospitals form.reserva").prepend('<div class="loading text-center"><img src="/media/img/loading-ajax.gif" alt="Carregant..."></div>');

            var calendar = $('body.inscdonacionshospitals form.reserva .calendari').parent('div');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            var now = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').data('now');
            var hospitalText = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"] option:selected').text();
            $.get( "./calendar.php", { hospital: hospital, dia: now}, function(data) {
                $('#hospital_day').val('');
                $('#hora').val('');
                calendar.html(data);
                $("#pas-hospital").addClass('completed');
                $("body.inscdonacionshospitals form.reserva .loading").remove();
                $(".detall").show();
                $("#detall-hospital").html(hospitalText);
                $("body.inscdonacionshospitals form.reserva .info-dia").hide();
            });
        }


        $('body.inscdonacionshospitals form.reserva select[name="hospital"]').on('change', function(event){
            event.preventDefault();

            $("body.inscdonacionshospitals form.reserva").prepend('<div class="loading text-center"><img src="/media/img/loading-ajax.gif" alt="Carregant..."></div>');

            var calendar = $('body.inscdonacionshospitals form.reserva .calendari').parent('div');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            var now = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').data('now');
            var hospitalText = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"] option:selected').text();
            $.get( "./calendar.php", { hospital: hospital, dia: now}, function(data) {
                $('#hospital_day').val('');
                $('#hora').val('');
                calendar.html(data);
                $("#pas-hospital").addClass('completed');
                $("body.inscdonacionshospitals form.reserva .loading").remove();
                $(".detall").show();
                $("#detall-hospital").html(hospitalText);
                $("body.inscdonacionshospitals form.reserva .info-dia").hide();
            });
            return false;
        });
        $('body.inscdonacionshospitals form.reserva').on('click', '.calendari thead a', function(event){
            event.preventDefault();

            $("body.inscdonacionshospitals form.reserva").prepend('<div class="loading text-center"><img src="/media/img/loading-ajax.gif" alt="Carregant..."></div>');

            var calendar = $(this).closest('div');
            var month = $(this).data('month');
            var day = $('#hospital_day').val();
            var now = $(this).data('now');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            $.get( "./calendar.php", { hospital: hospital, month: month, day: day, dia: now }, function(data) {
                calendar.html(data);
                $("body.inscdonacionshospitals form.reserva .loading").remove();
            });
            return false;
        });
        $('body.inscdonacionshospitals form.reserva').on('click', '.calendari tbody a', function(event){
            event.preventDefault();

            $("body.inscdonacionshospitals form.reserva #hora").prepend('<div class="loading"><img src="/media/img/loading-ajax.gif" width="25" alt="Carregant..."></div>');

            var horaSelect = $('#hora .opcions');
            horaSelect.html('');
            var hospital = $('body.inscdonacionshospitals form.reserva').find('select[name="hospital"]').val();
            var day = $(this).data('time');
            //$(this).closest('tbody').find('a').removeClass('cal--selected');
            $('table.calendari a').removeClass('cal--selected');
            $(this).addClass('cal--selected');
            $('#hospital_day').val($(this).data('time')).trigger('change');
            $.get( "./", { hospital: hospital, day: day }, function(data) {
                horaSelect.append(data);
                $("body.inscdonacionshospitals form.reserva #hora .info").remove();
                $("body.inscdonacionshospitals form.reserva #hora .loading").remove();
                if ($('input:radio[name="hour"]').is(':checked')){
                  $("#pas-hora").addClass('completed');
                }else{
                   $("#pas-hora").removeClass('completed');
                }
                $("#detall-dia").html(day);
                //inscSubmit();
            });
            return false;
        });
        $('body.inscdonacionshospitals form.reserva').on('click', ".opcions input", function() {
          $("#pas-hora").addClass('completed');
          $('.opcions label').removeClass('selected');
          $(this).parent().addClass('selected');
          //comprovar disponible
          var disponible = $(this).attr("data-disponible");
          var acompanyants = parseInt($('#acompanyants').val()) + 1;
          if (acompanyants > disponible){
              $(this).prop('checked', false);
              $('.opcions label').removeClass('selected');
              $.magnificPopup.open({
                items: {
                  src: '<div class="white-popup">' + $('#acompanyants').attr("data-error") + '</div>',
                  type: 'inline'
                }
              });
          }

          $("#detall-hora").html($(this).data("text"));
        });
         $('#acompanyants').on('change', function() {
          if($('input[name="hour"]').is(':checked')) {
             var disponible = $('input[name="hour"]:checked').attr("data-disponible");
             var acompanyants = parseInt($(this).val()) + 1;

             if (acompanyants > disponible){
                  $.magnificPopup.open({
                    items: {
                      src: '<div class="white-popup">' + $(this).attr("data-error") + '</div>',
                      type: 'inline'
                    }
                  });
                  $(this).val("0");
             }
          }
        });
        $('body.inscdonacionshospitals form.reserva').on('change', ".text-check", function() {
          var empty = $(this).parent().find("input.text-check").filter(function() {
              return this.value === "";
          });
          if(!empty.length) {
             $("#pas-dades").addClass('completed');
          }else{
            $("#pas-dades").removeClass('completed');
          }

        });
        /*$('body.inscdonacions form.hospital').on('change', '#hospital_hour', function(event){
            inscSubmit();
            return false;
        });*/
    }



    if (
        $('body.inscdonacionshospitals, body.inscdonacionsplasma').length > 0 &&
        $('body.inscdonacionshospitals input[name=dni], body.inscdonacionsplasma input[name=dni]').length > 0
    ) {
        var form = $('body.inscdonacionshospitals form.reserva, body.inscdonacionsplasma form.reserva');
        form.find('input[name=telefon],input[name=mobil]').parent().hide();
        form.find('button[type=submit]').prop('disabled', true);
//console.log(1);
        form.on('change', 'input[name=day], input[name=dni]', function(event){
            var dniInput = form.find('input[name=dni]');
            var dayInput = form.find('input[name=day]');


            //console.log({dni: dniInput.val(), day: dayInput.val()});
            if (dniInput.val() == '' || !dniInput.valid()) {
                return;
            }
            if (dayInput.val() == '') {
                return;
            }
//console.log(2);
            form.find("#dadespersonals").prepend('<div class="loading"><img src="/media/img/loading-ajax.gif" width="25" alt="Carregant..."></div>');
            $.post(dniInput.data('check'), { donant: dniInput.val(), dia: dayInput.val() }, function(data) {

//console.log(data);

                if (data.telefon) {
                    form.find('input[name=telefon],input[name=mobil]').parent().hide();
                } else {
                    form.find('input[name=telefon],input[name=mobil]').parent().show();
                }
                if (data.potDonar) {
                    $('form.reserva button[type=submit]').prop('disabled', false);
                } else {
                    $('form.reserva button[type=submit]').prop('disabled', true);
                }
                form.find('#dadespersonals .info.avis').remove();
                if (data.missatge && data.missatge != '') {
                    form.find("#dadespersonals").append('<div class="info avis"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"></path></svg>'+data.missatge+'</div>');
                }
                form.find("#dadespersonals .loading").remove();

            }, "json");
        });
    }
});



// --------------------
// Slider circular home
// --------------------
if (document.body.classList.contains('home')) {


	gsap.registerPlugin(MotionPathPlugin,DrawSVGPlugin);





	const circlePath = MotionPathPlugin.convertToPath("#holder", false)[0];
	circlePath.id = "circlePath";
	// document.querySelector("svg").prepend(circlePath);
	document.getElementById("circularSlider").prepend(circlePath);

	let items = gsap.utils.toArray(".item"),
	  numItems = items.length,
	  // itemStep = 1 / numItems,
	  itemStep = 0.5 / (numItems - 1), // Update to half
	  wrapProgress = gsap.utils.wrap(0, 1),
	  snap = gsap.utils.snap(itemStep),
	  wrapTracker = gsap.utils.wrap(0, numItems),
	  tracker = { item: 0 };

	gsap.set(circlePath, {
	  // Rotate the ciricle so that the start is at the top
	  rotate: -90,
	  transformOrigin: "center center"
	});

	gsap.set(items, {
	  motionPath: {
	    path: circlePath,
	    align: circlePath,
	    autoRotate: true,
	    alignOrigin: [0.5, 0],
	    end: (i) => {
	       const half = 0.5;
	      //const half = 0.25;
	      const distribute = half / (items.length - 1);
	      // console.warn(distribute);
	      return distribute * i;
	    }
	  },
	});

	const tl = gsap.timeline({ paused: true, reversed: true });

	tl.to(".wrapper-slider", {
	  rotation: 360,
	  transformOrigin: "center",
	  duration: 1,
	  ease: "none"
	});

	tl.to(
	  items,
	  {
	    // rotation: "-=360",
	    // transformOrigin: "center center",
	    duration: 1,
	    ease: "none"
	  },
	  0
	);

	tl.to(
	  tracker,
	  {
	    item: numItems,
	    duration: 1,
	    ease: "none",
	    modifiers: {
	      item: (value) => wrapTracker(numItems - Math.round(value))
	    }
	  },
	  0
	);

	items.forEach(function (el, i) {

	  el.addEventListener("click", function () {
	    var current = tracker.item,
	      activeItem = i;

	    if (i === current) {
	      return;
	    }

	    //set active item to the item that was clicked and remove active class from all items
	    document.querySelector(".item.active").classList.remove("active");
	    items[activeItem].classList.add("active");

	    var diff = current - i;

	    if (Math.abs(diff) < numItems / 2) {
	      moveWheel(diff * itemStep);
	    } else {
	      var amt = numItems - Math.abs(diff);

	      if (current > i) {
	        moveWheel(amt * -itemStep);
	      } else {
	        moveWheel(amt * itemStep);
	      }
	    }
	  });
	});


	var currentIndex = 0; // Moved outside to maintain state across clicks
	function updateButtonStates() {
	  const nextButton = document.getElementById("next");
	  const prevButton = document.getElementById("prev");

	  // Disable 'next' button if current index is 4 (5th item, as index is 0-based)
	  if (currentIndex === 4) {
	    nextButton.setAttribute('disabled', 'disabled');
	  } else {
	    nextButton.removeAttribute('disabled');
	  }

	  // Disable 'prev' button if current index is 0 (1st item)
	  if (currentIndex === 0) {
	    prevButton.setAttribute('disabled', 'disabled');
	  } else {
	    prevButton.removeAttribute('disabled');
	  }
	}

	// Initial call to set the button states when the page loads
	updateButtonStates();



	document.getElementById("next").addEventListener("click", function () {
	  currentIndex = (currentIndex + 1) % items.length; // Increment and wrap around

	  // Log current item for debugging
	  console.log("Current Item8: ", items[currentIndex]);

	  moveWheel(-itemStep); // Move the wheel
	   updateButtonStates(); // Update button states after moving
	});


	document.getElementById("prev").addEventListener("click", function () {
	  currentIndex = (currentIndex - 1 + items.length) % items.length;
	   updateButtonStates(); // Update button states after moving
	  return moveWheel(itemStep);
	});



	/* mobile touch*/
	let isDragging = false;
	let startX, startY, sliderWidth;
	let touchThreshold = 5; // Lower value requires a longer swipe

	const wrapper = document.querySelector('.circular-slider-wrapper-2');
	const nextButton = document.getElementById("next");
	const prevButton = document.getElementById("prev");

	sliderWidth = wrapper.offsetWidth; // Get the width of the slider

	wrapper.addEventListener('touchstart', function(e) {
	  isDragging = false;
	  startX = e.touches[0].pageX;
	  startY = e.touches[0].pageY;
	});

	wrapper.addEventListener('touchmove', function(e) {
	  let moveX = Math.abs(e.touches[0].pageX - startX);
	  let moveY = Math.abs(e.touches[0].pageY - startY);

	  if (moveX > moveY && (moveX / sliderWidth) > (1 / touchThreshold)) {
	    // Horizontal swipe is dominant and exceeds the relative threshold
	    isDragging = true;
	  }
	});

	wrapper.addEventListener('touchend', function(e) {
	  if (isDragging) {
	    let endX = e.changedTouches[0].pageX;

	    if (endX > startX) {
	      prevButton.click(); // Swiped right, trigger previous
	    } else {
	      nextButton.click(); // Swiped left, trigger next
	    }
	  }
	  isDragging = false;
	});


	/* drag desktop */
	wrapper.addEventListener('mousedown', function(e) {
	  e.preventDefault(); // Prevent default to avoid text selection
	  isDragging = true;
	  startX = e.pageX;
	});

	document.addEventListener('mousemove', function(e) {
	  if (!isDragging) return;
	  let moveX = Math.abs(e.pageX - startX);

	  if ((moveX / sliderWidth) > (1 / touchThreshold)) {
	    // Drag exceeds the relative threshold
	    isDragging = false; // Prevent multiple triggers
	    if (e.pageX > startX) {
	      prevButton.click(); // Dragged right, trigger previous
	    } else {
	      nextButton.click(); // Dragged left, trigger next
	    }
	  }
	});

	document.addEventListener('mouseup', function() {
	  isDragging = false;
	});


	function moveWheel(amount, i, index) {
	  /*let progress = tl.progress();
	  tl.progress(wrapProgress(snap(tl.progress() + amount)));
	  let next = tracker.item;
	  tl.progress(progress);

	  document.querySelector(".item.active").classList.remove("active");
	  items[next].classList.add("active");*/

	  let progress = tl.progress();
	  tl.progress(wrapProgress(snap(tl.progress() + amount)));
	  let next = (currentIndex + items.length) % items.length; // Calculate next index with wrap around
	  tl.progress(progress);

	  // Update active class for items
	  document.querySelector(".item.active").classList.remove("active");
	  items[next].classList.add("active");

	  gsap.to(tl, {
	    progress: snap(tl.progress() + amount),
	    modifiers: {
	      progress: wrapProgress
	    }
	  });

	}
}
